var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"header-box"},[_c('Header',{attrs:{"type":1},on:{"handleScroll":_vm.handleScroll}}),_c('div',{staticClass:"heading"},[_vm._v("高效、精准、真实的数据聚合平台")]),_vm._m(0)],1),_c('div',{staticClass:"floating-boxs"},_vm._l((_vm.floatings),function(item,index){return _c('div',{key:index,staticClass:"floating-item"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(item.number)),_c('span',[_vm._v("+")])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),(index !== _vm.floatings.length - 1)?_c('div',{staticClass:"line"}):_vm._e()])}),0),_vm._m(1),_c('div',{staticClass:"market"},[_c('img',{staticClass:"market-01",attrs:{"src":require("@/assets/images/home/01.png"),"alt":"图片"}}),_vm._m(2),_vm._m(3),_c('div',{staticClass:"img-box"},[_c('div',{staticClass:"imgs"},_vm._l((_vm.imgShowlist[0].imgs),function(item){return _c('img',{key:item,attrs:{"src":item,"alt":"图片"}})}),0),_c('div',{staticClass:"imgs"},_vm._l((_vm.imgShowlist[1].imgs),function(item){return _c('img',{key:item,attrs:{"src":item,"alt":"图片"}})}),0)])]),_vm._m(4),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle"},[_c('p',[_vm._v(" 我们帮您精准找到您需要的全球样本，医生、车主、患者、母婴、管理者等等； ")]),_c('p',[_vm._v("我们帮您轻松实现流量变现，最大化发现社区会员的价值；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-advantages",attrs:{"id":"advantages"}},[_c('div',{staticClass:"secure"},[_vm._v("更高效")]),_c('div',{staticClass:"income"},[_vm._v("收益高")]),_c('div',{staticClass:"precise"},[_vm._v("更精准")]),_c('div',{staticClass:"product"},[_vm._v("产品优势")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"market-title"},[_c('span'),_c('div',[_vm._v("效精准数据采集")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"illustrates"},[_c('div',{staticClass:"illustrates-left"},[_vm._v(" 问卷集拥有端到端解决方案的DIY市场研究提供商，专注于移动优先的方法，并提供来自全球1.3亿以上真实消费者的实时响应。我们提供最广泛的分销网络和狭窄的目标，可以快速、大规模地为任何研究活动找到合适的受众。 ")]),_c('div',{staticClass:"illustrates-right"},[_vm._v(" 问卷集通过1350多个应用程序/网站合作，覆盖全球78个市场（国家）的样本，我们不仅全天候为您提供支持，而且我们还通过AI机器学习技术训练出一个庞大的质量检查系统——剔除调查欺诈、机器人、胡言乱语的答案等等——以确保您获得最优质的数据。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow-back"},[_c('div',{staticClass:"flow-box"},[_c('img',{staticClass:"flow-left",attrs:{"src":"https://wjj-core.oss-cn-beijing.aliyuncs.com/flow-left.png","alt":"图片"}}),_c('div',{staticClass:"flow-right"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"flow-title"},[_c('span'),_c('div',[_vm._v("全媒体流量变现")])]),_c('img',{staticClass:"flow-02",attrs:{"src":require("@/assets/images/home/02.png"),"alt":"图片"}})]),_c('div',{staticClass:"box-characters"},[_vm._v(" 通过将你的社区/网站/APP/微信群/公众号等对接到问卷集上,让你的社区会员参与问卷答题发表意见、产品测试，电话访问等项目获得奖励,从而增加社区的粘度,也让你的社区价值轻松变现； ")]),_c('img',{staticClass:"map",attrs:{"src":"https://wjj-core.oss-cn-beijing.aliyuncs.com/flow-right.png","alt":"图片"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commitment"},[_c('img',{staticClass:"commitment-03",attrs:{"src":require("@/assets/images/home/03.png"),"alt":"图片"}}),_c('div',{staticClass:"commitment-title"},[_c('span'),_c('div',[_vm._v("我们的承诺")])]),_c('div',{staticClass:"commitment-main"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/home/speed.png"),"alt":"图片"}}),_c('div',[_vm._v("速度快")])]),_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/home/quality.png"),"alt":"图片"}}),_c('div',[_vm._v("质量高")])]),_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/home/price.png"),"alt":"图片"}}),_c('div',[_vm._v("价格低")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-content"},[_c('p',[_vm._v(" 问卷集拥有1350+合作平台，覆盖了大量的人群,通过精细的算法可以为你的研究项目快速找到所需要的样本； ")]),_c('p',[_vm._v(" 问卷集通过AI机器学习技术训练出一个庞大的质量检查系统——剔除调查欺诈、机器人、胡言乱语的答案等等——以确保您获得最优质的数据。 ")]),_c('p',[_vm._v(" 问卷集发展10年间通过合作平台的深度合作，优化资源，极大的降低了运作成本，所以可以提供给客户的极其优惠的价格。 ")])])])])])
}]

export { render, staticRenderFns }