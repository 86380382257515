<template>
  <div class="home">
    <div class="header-box">
      <Header :type="1" @handleScroll="handleScroll"></Header>
      <div class="heading">高效、精准、真实的数据聚合平台</div>
      <div class="subtitle">
        <p>
          我们帮您精准找到您需要的全球样本，医生、车主、患者、母婴、管理者等等；
        </p>
        <p>我们帮您轻松实现流量变现，最大化发现社区会员的价值；</p>
      </div>
    </div>
    <!-- 数值信息 -->
    <div class="floating-boxs">
      <div
        v-for="(item, index) in floatings"
        :key="index"
        class="floating-item"
      >
        <div class="number">{{ item.number }}<span>+</span></div>
        <div class="title">{{ item.title }}</div>
        <div class="line" v-if="index !== floatings.length - 1"></div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="product-advantages" id="advantages">
      <div class="secure">更高效</div>
      <div class="income">收益高</div>
      <div class="precise">更精准</div>
      <div class="product">产品优势</div>
    </div>
    <!-- 01 市场调研-->
    <div class="market">
      <img class="market-01" src="@/assets/images/home/01.png" alt="图片" />
      <div class="market-title">
        <span></span>
        <div>效精准数据采集</div>
      </div>
      <div class="illustrates">
        <div class="illustrates-left">
          问卷集拥有端到端解决方案的DIY市场研究提供商，专注于移动优先的方法，并提供来自全球1.3亿以上真实消费者的实时响应。我们提供最广泛的分销网络和狭窄的目标，可以快速、大规模地为任何研究活动找到合适的受众。
        </div>
        <div class="illustrates-right">
          问卷集通过1350多个应用程序/网站合作，覆盖全球78个市场（国家）的样本，我们不仅全天候为您提供支持，而且我们还通过AI机器学习技术训练出一个庞大的质量检查系统——剔除调查欺诈、机器人、胡言乱语的答案等等——以确保您获得最优质的数据。
        </div>
      </div>
      <div class="img-box">
        <div class="imgs">
          <img
            :src="item"
            alt="图片"
            v-for="item in imgShowlist[0].imgs"
            :key="item"
          />
        </div>
        <div class="imgs">
          <img
            :src="item"
            alt="图片"
            v-for="item in imgShowlist[1].imgs"
            :key="item"
          />
        </div>
      </div>
    </div>
    <!-- 02 流量变现 -->
    <div class="flow-back">
      <div class="flow-box">
        <img
          class="flow-left"
          src="https://wjj-core.oss-cn-beijing.aliyuncs.com/flow-left.png"
          alt="图片"
        />
        <div class="flow-right">
          <div class="title-box">
            <div class="flow-title">
              <span></span>
              <div>全媒体流量变现</div>
            </div>
            <img class="flow-02" src="@/assets/images/home/02.png" alt="图片" />
          </div>
          <div class="box-characters">
            通过将你的社区/网站/APP/微信群/公众号等对接到问卷集上,让你的社区会员参与问卷答题发表意见、产品测试，电话访问等项目获得奖励,从而增加社区的粘度,也让你的社区价值轻松变现；
          </div>
          <img
            class="map"
            src="https://wjj-core.oss-cn-beijing.aliyuncs.com/flow-right.png"
            alt="图片"
          />
        </div>
      </div>
    </div>
    <!-- 03 承诺 -->
    <div class="commitment">
      <img class="commitment-03" src="@/assets/images/home/03.png" alt="图片" />
      <div class="commitment-title">
        <span></span>
        <div>我们的承诺</div>
      </div>
      <div class="commitment-main">
        <div class="left">
          <div class="img-box">
            <img src="@/assets/images/home/speed.png" alt="图片" />
            <div>速度快</div>
          </div>
          <div class="img-box">
            <img src="@/assets/images/home/quality.png" alt="图片" />
            <div>质量高</div>
          </div>
          <div class="img-box">
            <img src="@/assets/images/home/price.png" alt="图片" />
            <div>价格低</div>
          </div>
        </div>
        <div class="right">
          <div class="right-content">
            <p>
              问卷集拥有1350+合作平台，覆盖了大量的人群,通过精细的算法可以为你的研究项目快速找到所需要的样本；
            </p>
            <p>
              问卷集通过AI机器学习技术训练出一个庞大的质量检查系统——剔除调查欺诈、机器人、胡言乱语的答案等等——以确保您获得最优质的数据。
            </p>
            <p>
              问卷集发展10年间通过合作平台的深度合作，优化资源，极大的降低了运作成本，所以可以提供给客户的极其优惠的价格。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { floatings, imglist } from "@/utils/data";
export default {
  components: { Header, Footer },
  data() {
    return {
      floatings: floatings,
      imgShowlist: imglist,
      timer: null,
    };
  },
  methods: {
    handleScroll() {
      console.log(1234566, "advantages");
      var element = document.getElementById("advantages");
      element.scrollIntoView();
    },
  },
  mounted() {
    const dome = document.getElementsByClassName("imgs")[0];
    const dome1 = document.getElementsByClassName("imgs")[1];
    dome1.style.display = "none";
    this.timer = setInterval(() => {
      //如果1是隐藏的，那么0执行动画
      if (dome1.style.display === "none") {
        dome.className = "imgs img-show";
        //动画执行完成后
        setTimeout(() => {
          dome1.style.display = "flex";
          dome.style.display = "none";
          dome.className = "imgs";
        }, 500);
      }

      if (dome.style.display === "none") {
        dome1.className = "imgs img-show";
        //动画执行完成后
        setTimeout(() => {
          dome.style.display = "flex";
          dome1.style.display = "none";
          dome1.className = "imgs";
        }, 500);
      }
    }, 1800);
    if (this.$route.query.type == 1) {
      this.handleScroll();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.header-box {
  width: 100%;
  height: 686px;
  background-image: url("https://wjj-core.oss-cn-beijing.aliyuncs.com/bg-home.png");
  background-size: cover;
  background-position-x: 40%;
  .heading {
    text-align: center;
    font-size: 50px;
    color: #ffffff;
    margin-top: 200px;
  }
  .subtitle {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    margin-top: 65px;
  }
}
.floating-boxs {
  position: relative;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  width: 964px;
  height: 150px;
  background: #fff;
  box-shadow: 0px 3px 20px 1px rgba(1, 2, 2, 0.08);
  border-radius: 4px;
  display: flex;
  .floating-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 196px;
    height: 150px;
    position: relative;
    transition: hei 1s;
    cursor: pointer;
    .number {
      font-size: 32px;
      color: #4578ff;
      
      span {
        font-weight: bold;
      }
    }
    .title {
      color: #666666;
      font-size: 16px;
    }
    .line {
      position: absolute;
      right: 0;
      width: 1px;
      height: 65px;
      background-color: #e3e3e3;
    }
  }
  .floating-item:hover {
    height: 190px;
    background-color: #4578ff;
    border-radius: 4px;
    position: relative;
    top: -20px;
    .line {
      display: none;
    }
    .number {
      color: #fff;
    }
    .title {
      color: #fff;
    }
  }
}
.product-advantages {
  width: 538px;
  height: 388px;
  background-image: url("../assets/images/home/product-advantages.png");
  background-size: 100% 100%;
  margin: 0 auto;
  position: relative;
  color: #232323;
  div {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
  }
  .secure {
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
  }
  .income {
    left: -60px;
    top: 50%;
    transform: translateY(-100%);
  }
  .precise {
    right: -60px;
    top: 50%;
    transform: translateY(-100%);
  }
  .product {
    top: 137px;
    left: 224px;
    font-size: 22px;
    
  }
}
// 市场调研
.market {
  width: calc(100% - 280px);
  padding: 0 140px;
  color: #232323;
  .market-01 {
    width: 55.33px;
    height: 54.27px;
  }
  .market-title {
    font-size: 18px;
    margin-top: 27px;
    display: flex;
    align-items: center;
    div {
      font-weight: bold;
    }
    span {
      display: inline-block;
      width: 2px;
      height: 14px;
      background-color: #4578ff;
      margin-right: 7px;
    }
  }
  .illustrates {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .illustrates-left,
    .illustrates-right {
      width: 392px;
      font-size: 14px;
      line-height: 30px;
    }
  }
  .img-box {
    margin-top: 113px;
    .imgs {
      display: flex;
      justify-content: space-around;
      opacity: 1;
      transition: opacity 0.5s;
      img {
        width: 180px;
        height: 68px;
      }
    }
    .img-show {
      opacity: 0;
    }
  }
}
.flow-back {
  height: 526px;
  background: rgba(69, 120, 255, 0.05);
  margin-top: 92px;
  position: relative;
  .flow-box {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .flow-left {
      width: 496px;
      height: 589px;
      margin-left: 176px;
      margin-top: 64px;
    }
    .flow-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 25px;
      margin-right: 140px;
      .title-box {
        display: flex;
        .flow-title {
          font-size: 18px;
          margin-right: 46px;
          display: flex;
          align-items: center;
          margin-top: 20px;
          div {
            font-weight: bold;
          }
          span {
            display: inline-block;
            width: 2px;
            height: 14px;
            background-color: #4578ff;
            margin-right: 7px;
          }
        }
        .flow-02 {
          width: 55.33px;
          height: 54.27px;
        }
      }
      .box-characters {
        width: 283px;
        font-size: 14px;
        line-height: 30px;
        margin-top: 57px;
      }
      .map {
        width: 664px;
        height: 380px;
      }
    }
  }
}

.commitment {
  width: calc(100% - 280px);
  padding: 0 140px;
  color: #232323;
  margin-top: 170px;
  .commitment-03 {
    width: 55.33px;
    height: 54.27px;
  }
  .commitment-title {
    font-size: 18px;
    margin-top: 27px;
    display: flex;
    align-items: center;
    div {
      font-weight: bold;
    }
    span {
      display: inline-block;
      width: 2px;
      height: 14px;
      background-color: #4578ff;
      margin-right: 7px;
    }
  }
  .commitment-main {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .left {
      width: calc(100% - 700px);
      display: flex;
      justify-content: space-between;
      .img-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 78px;
          height: 78px;
        }
        div {
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
    .right {
      width: 580px;
      min-height: 300px;
      background: #4578ff;
      border-radius: 150px 0 0 150px;
      opacity: 1;
      .right-content {
        width: 350px;
        margin: 30px auto;
        color: #fff;
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        text-indent: 2em;
        p{
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>